import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  Grid,
  makeStyles,
  Typography,
  Table,
  TableRow,
  TableCell,
  Link,
  TableBody,
  IconButton,
  Toolbar,
} from "@material-ui/core"
import Logo1 from "./logo1"
import {
  DirectionsBike,
  Facebook,
  Instagram,
  LocationOn,
} from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "stretch",
    backgroundColor: "#000",
    color: "#fff",
  },
  footerGrids: {
    padding: "1%",
  },
  trow: {
    borderStyle: "hidden",
    backgroundColor: "#000",
    color: "#fff",
  },
  title: {
    marginTop: theme.spacing(1),
    fontSize: "4vh",
    textAlign: "center",
  },
  lineone: {
    fontSize: "2vh",
    textAlign: "center",
  },
  linetwo: {
    fontSize: "2vh",
    textAlign: "center",
  },
  linethree: {
    fontSize: "2vh",
    textAlign: "center",
  },

  tablica: {
    color: "#fff",
    fontSize: "1.7vh",
  },
  social: {
    justifyContent: "center",
    flex: 1,
    paddingLeft: "1%",
    [theme.breakpoints.up("md")]: {
      justifyContent: "left",
    },
  },
  iconSize: {
    fontSize: "3vh",
  },
  facebook: {
    color: "#fff",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.2)",
      color: "#3578E5",
    },
  },
  instagram: {
    color: "#fff",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.2)",
      color: "#C13584",
    },
  },
  strava: {
    color: "#fff",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.2)",
      color: "#F77737",
    },
  },
  location: {
    color: "#fff",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.2)",
      color: "#FD1D1D",
    },
  },
  centerRow: {
    padding: theme.spacing(0),
    textAlign: "center",
    backgroundColor: "#000",
    color: "#fff",
  },
}))

const Footer = () => {
  const classes = useStyles()
  const d = useStaticQuery(
    graphql`
      query Footer {
        site {
          siteMetadata {
            address {
              line1
              line2
              line3
            }
            social {
              instagram
              facebook
              googleplus
            }
            workingHours {
              label
              time
            }
            contact {
              phones {
                label
                short
                full
              }
              email
            }
            officialTitle
          }
        }
      }
    `
  )
  const address = d.site.siteMetadata.address
  const workingHours = d.site.siteMetadata.workingHours
  const contact = d.site.siteMetadata.contact
  const ofTitle = d.site.siteMetadata.officialTitle
  return (
    <>
      <Grid container className={classes.root} spacing={0} direction="row">
        <Grid item xs={12} md={4} className={classes.footerGrids}>
          <Typography
            component="h3"
            variant="h4"
            color="inherit"
            gutterBottom
            className={classes.title}
          >
            Kontakt
          </Typography>
          <Table size="small">
            <TableBody>
              {contact.phones.map(p => (
                <TableRow key={p.short} className={classes.trow}>
                  <TableCell className={classes.tablica}>{p.label}</TableCell>
                  <TableCell align="right">
                    <Link className={classes.tablica} href={`tel:${p.full}`}>
                      {p.short}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow className={classes.trow}>
                <TableCell className={classes.tablica}>Email</TableCell>
                <TableCell align="right">
                  <Link
                    className={classes.tablica}
                    href={`mailto:${contact.email}?Subject=Upit%20sa%20web%20stranice`}
                  >
                    {contact.email}
                  </Link>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Toolbar component="nav" variant="dense" className={classes.social}>
            <IconButton
              className={classes.facebook}
              edge="false"
              color="inherit"
              href="https://www.facebook.com/ciklosport"
              target="_blank"
            >
              <Facebook className={classes.iconSize} />
            </IconButton>
            <IconButton
              className={classes.instagram}
              color="inherit"
              edge="false"
              href="https://www.instagram.com/ciklosport/"
              target="_blank"
            >
              <Instagram className={classes.iconSize} />
            </IconButton>
            <IconButton
              className={classes.strava}
              color="inherit"
              edge="false"
              href="https://www.strava.com/clubs/ciklosport"
              target="_blank"
            >
              <DirectionsBike className={classes.iconSize} />
            </IconButton>
            <IconButton
              className={classes.location}
              color="inherit"
              edge="false"
              href="https://g.page/CikloSport?share"
              target="_blank"
            >
              <LocationOn className={classes.iconSize} />
            </IconButton>
          </Toolbar>
        </Grid>
        <Grid item xs={12} md={4} className={classes.footerGrids}>
          <Typography
            component="h3"
            variant="h4"
            color="inherit"
            gutterBottom
            className={classes.title}
          >
            Adresa
          </Typography>
          <Typography className={classes.lineone}>{address.line1}</Typography>
          <Typography className={classes.linetwo}>{address.line2}</Typography>
          <Typography className={classes.linethree}>{address.line3}</Typography>
        </Grid>
        <Grid item xs={12} md={4} className={classes.footerGrids}>
          <Typography
            component="h3"
            variant="h4"
            color="inherit"
            gutterBottom
            className={classes.title}
          >
            Radno vrijeme
          </Typography>
          <Table size="small">
            <TableBody>
              {workingHours.map(w => (
                <TableRow key={w.label} className={classes.trow}>
                  <TableCell className={classes.tablica}>{w.label}</TableCell>
                  <TableCell className={classes.tablica} align="right">
                    {w.time}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={12} className={classes.centerRow}>
          <Logo1 height={100} />
        </Grid>
        <Grid item xs={12} className={classes.centerRow}>
          <Typography>© 2025 {ofTitle}</Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default Footer
