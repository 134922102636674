import React from "react"
import { Link as Glink } from "gatsby"
import {
  Grid,
  Link,
  Card,
  CardContent,
  CardActions,
  Typography,
  makeStyles,
  Chip,
} from "@material-ui/core"
import { AddCircle, RemoveCircle } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  item: {
    width: "100%",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
    "&:hover": {
      boxShadow: "0px 0px 4px rgba(0,0,0,1)",
    },
  },
  content: {
    display: "flex",
    flex: "1 0 auto",
    alignItems: "flex-start",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  chip: {
    marginLeft: "auto",
    borderRadius: 5,
    flexDirection: "row-reverse",
    paddingLeft: "8px",
    paddingRight: "8px",
    width: "100%",
  },
  RemoveCircleIcon: {
    color: "#ff0000",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
      color: "#d10000",
    },
  },
  AddCircleIcon: {
    color: "#22ff00",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
      color: "#1bc700",
    },
  },
  media: {
    maxHeight: "250px",
    backgroundSize: "contain",
    objectFit: "contain",
  },
}))

const BasketItem = props => {
  const classes = useStyles()
  const { item } = props
  const handleIncrement = () => props.onIncrement(item)
  const handleDecrement = () => props.onDecrement(item)

  return (
    <Grid item sm={6} md={4} lg={3} className={classes.item}>
      <Card variant="outlined" className={classes.card}>
        <img className={classes.media} srcSet={item.image} alt={item.title} />
        <CardContent className={classes.content}>
          <div>
            <Typography variant="h5" component="h2">
              <Link component={Glink} to={item.slug}>
                {item.title}
              </Link>
            </Typography>
            <Typography variant="body2" component="p">
              Cijena:{" "}
              {item.price.toLocaleString("hr-HR", {
                minimumFractionDigits: 2,
              })}
              &nbsp;€
              <br />
              {item.extraDiscount && (
                <Typography variant="body2" component="p">
                  Cijena sa dodatnim popustom:{" "}
                  {Math.abs(
                    ((100 - item.extraDiscount) / 100) * item.price
                  ).toLocaleString("hr-HR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  &nbsp;€
                </Typography>
              )}
              {item.specialDeal && (
                <Typography variant="body2" component="p">
                  Cijena sa dodatnim popustom:{" "}
                  {Math.abs(
                    ((100 - item.specialDeal) / 100) * item.price
                  ).toLocaleString("hr-HR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  &nbsp;€
                </Typography>
              )}
              {item.mondrakerDeal && (
                <Typography variant="body2" component="p">
                  Cijena sa dodatnim popustom:{" "}
                  {Math.abs(
                    ((100 - item.mondrakerDeal) / 100) * item.price
                  ).toLocaleString("hr-HR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  &nbsp;€
                </Typography>
              )}
            </Typography>
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Chip
            className={classes.chip}
            deleteIcon={<RemoveCircle className={classes.RemoveCircleIcon} />}
            label={
              <Typography variant="body1">Količina: {item.quantity}</Typography>
            }
            clickable
            onDelete={handleDecrement}
            variant="outlined"
            onClick={handleIncrement}
            icon={<AddCircle className={classes.AddCircleIcon} />}
          />
        </CardActions>
      </Card>
    </Grid>
  )
}

export default BasketItem
